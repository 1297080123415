<template>
  <div>
    <div v-if="'hetun' === heTunInfo"><suggestionsFeedbackZj></suggestionsFeedbackZj></div>
    <div v-else>
      <div class="title">合作建议及反馈</div>
      <div style="text-align: center; line-height: 20px; margin-top: 10px;">
        发布时间：2022-04-11 20:19:26
      </div>
      <div style="font-size: 14px; padding: 35px;"><p>&nbsp;</p>
        <p><strong>尊敬的{{ $tp().OPEN_COM_NAME }}伙伴：</strong><br><strong>诚信正直是{{ $tp().OPEN_COM_NAME }}业务运营的基础，也是{{ $tp().OPEN_COM_NAME }}对员工的最基本要求。 在商业经营过程中，我们决不姑息任何违法、违规或舞弊行为；也不允许任何损害公司和员工利益的行为存在。 如您在与{{ $tp().OPEN_COM_NAME }}的商业交易中或在{{ $tp().OPEN_COM_NAME }}工作中受到利益侵害或者不公平、不公正的对待，或您知悉任何{{ $tp().OPEN_COM_NAME }}员工在商业交易中存在有违法、违规或舞弊行为，可通过以下渠道向我们反映</strong></p>
        <p>欢迎您的来电</p>
        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;国内：  {{ $tp().OPEN_PHONE }}</p>
        <p>廉政邮箱：{{ $tp().OPEN_EMAIL }}</p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/11/15 10:50
 * @version 1.0
 * @description
 */
import suggestionsFeedbackZj from '@/views/Help/components/suggestionsFeedbackZj'

export default {
  name: 'SuggestionsFeedback',
  components: { suggestionsFeedbackZj },
  computed: {
    heTunInfo() {
      if (process.env.NODE_ENV === 'production') {
        return process.env.NODE_ENV
      } else if (process.env.NODE_ENV === 'pre') {
        return process.env.NODE_ENV
      } else if (process.env.NODE_ENV === 'hetun') {
        return process.env.NODE_ENV
      } else {
        return process.env.NODE_ENV
      }
    }
  }
}
</script>

<style scoped>

</style>
