<template>
  <div>
    <div class="title">合作建议及反馈</div>
    <div data-v-0ab10f90="" style="font-size: 14px;">
      <p style="text-align: center;font-size: 13px;"><span> 发布时间：2022-04-11 20:19:26 </span></p>
      <p><b>尊敬的深圳市合囤供应链有限责任公司伙伴：</b></p>
      <p style="line-height: 27px;">
        <b>诚信正直是深圳市合囤供应链有限责任公司业务运营的基础，也是深圳市合囤供应链有限责任公司对员工的最基本要求。在商业经营过程中，我们决不姑息任何违法、违规或舞弊行为；也不允许任何损害公司和员工利益的行为存在。如您在与深圳市合囤供应链有限责任公司的商业交易中或在深圳市合囤供应链有限责任公司工作中受到利益或者不公平、不公正的对待，或您知悉任何深圳市合囤供应链有限责任公司员工在商业交易中存在有违法、违规或舞弊行为，可通过以下渠道向我们反映</b>
      </p>
      <p><span>欢迎您的来电</span></p>
      <p style="text-indent: 4em;"><span>国内：17328751343</span></p>
      <p><span>廉政邮箱：monroe@szshtgylyxzrg.wecom.work</span></p>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'suggestionsFeedbackZj',
  computed: {
    ...mapGetters(['webKeyword'])
  }
}
</script>

<style scoped>

</style>
